import { makeAutoObservable, configure } from 'mobx';
import sessionFactory from './sessionFactory';

configure({
    enforceActions: 'never',
});

export default () => {
    const store = {
        sessions: [],
        sessionsLoading: false,
        sessionCreateFailed: false,
        currentSession: {
            sessionID: null,
        },
        globalSessions: {
            data: {
                places: [],
                cleanPlaces: [],
                types: [],
                report: {},
                resources: [],
                rules: [],
                filteredRules: [],
                zones: [],
                orders: [],
                filteredOrders: [],
                liveTransporters: [],
            },
            activeFilter: 'none',
            activeFilters: [],
        },
    };

    const factory = sessionFactory({ store });
    const {
        refreshSessions,
        deleteSession,
        selectSession,
        createSession,
        currentSession,
        globalSessions,
    } = factory;

    Object.assign(store, {
        refreshSessions,
        deleteSession,
        selectSession,
        createSession,
        currentSession: Object.assign(currentSession, store.currentSession),
        globalSessions: Object.assign(globalSessions, store.globalSessions),
    });
    return makeAutoObservable(store);
};
