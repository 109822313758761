import { ChangeEvent, useState } from 'react';
import { SessionOperationConfig } from '@components/SolverSessions/SessionCreator/SessionCreator.model';

export const sessionTypesDefaults = {
    FH: true,
    BH: false,
    D2D: false,
};

const useSessionTypes = () => {
    const [checkedStates, setCheckedStates] =
        useState<SessionOperationConfig>(sessionTypesDefaults);

    const [isRunSolver, setIsRunSolver] = useState<boolean>(false);

    const handleSessionTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setCheckedStates((prev) => {
            const newState = { ...prev, [name]: checked };
            if (!Object.values(newState).some((state) => state === true))
                newState.FH = true;
            return newState;
        });
    };

    const resetSessionTypes = () => {
        setCheckedStates(sessionTypesDefaults);
    };

    return {
        checkedStates,
        isRunSolver,
        resetSessionTypes,
        handleSessionTypeChange,
        setIsRunSolver,
    };
};

export default useSessionTypes;
