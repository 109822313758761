import { FeatureFlagRole } from '@models';

const devRoleName = 'DEV';

interface KeyCloakAccess {
    realmAccess: { roles: Array<string> };
}

interface FeatureFlag {
    name: FeatureFlagRole;
    isActive: boolean;
}

const hasRealAccess = (keycloak: KeyCloakAccess, roleName: string) => {
    const realmAccess = keycloak?.realmAccess?.roles || [];
    return realmAccess.findIndex((role) => role === roleName) !== -1;
};

const featureFlags = (keycloak: KeyCloakAccess): Array<FeatureFlag> => {
    return [
        {
            name: 'continueSolverButton',
            isActive: hasRealAccess(keycloak, devRoleName),
        },
        {
            name: 'runSolverEfficiencyMode',
            isActive: hasRealAccess(keycloak, devRoleName),
        },
        // TODO: as we don't have other role than dev, this should serve the purpose
        {
            name: 'genericDevFlag',
            isActive:
                hasRealAccess(keycloak, devRoleName) ||
                window.location.hostname === 'localhost',
        },
    ];
};

export default featureFlags;
